@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css);
@charset "UTF-8";
@font-face {
  font-family: 'Avenir Next Bold';
  src: url(/static/media/AvenirNext-Bold.8746f24d.eot);
  src: url(/static/media/AvenirNext-Bold.8746f24d.eot?#iefix) format("embedded-opentype"), url(/static/media/AvenirNext-Bold.3eb427fb.woff) format("woff"), url(/static/media/AvenirNext-Bold.24fbc8fa.ttf) format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Avenir Next Medium';
  src: url(/static/media/AvenirNext-Medium.b77b2629.eot);
  src: url(/static/media/AvenirNext-Medium.b77b2629.eot?#iefix) format("embedded-opentype"), url(/static/media/AvenirNext-Medium.fcd25550.woff) format("woff"), url(/static/media/AvenirNext-Medium.c79e8876.ttf) format("truetype");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Avenir Next Regular';
  src: url(/static/media/AvenirNext-Regular.d71b9824.eot);
  src: url(/static/media/AvenirNext-Regular.d71b9824.eot?#iefix) format("embedded-opentype"), url(/static/media/AvenirNext-Regular.bb1d34b7.woff) format("woff"), url(/static/media/AvenirNext-Regular.1f161181.ttf) format("truetype");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Avenir Next Demi Bold';
  src: url(/static/media/AvenirNext-DemiBold.c0094807.eot);
  src: url(/static/media/AvenirNext-DemiBold.c0094807.eot?#iefix) format("embedded-opentype"), url(/static/media/AvenirNext-DemiBold.21b8b38b.woff) format("woff"), url(/static/media/AvenirNext-DemiBold.38273c12.ttf) format("truetype");
  font-weight: 200;
  font-style: bold; }

.demi-bold, label a.active, .custom-input .form-control {
  font-family: 'Avenir Next Demi Bold';
  color: black;
  font-size: 14px; }

.fontAwsome {
  font-family: FontAwesome; }

.bold {
  font-family: 'Avenir Next Bold';
  color: black;
  font-size: 20px; }

.logo {
  position: relative;
  padding: 7px 0.8rem;
  z-index: 4; }
  .logo a.logo-mini {
    opacity: 1;
    float: left;
    width: 34px;
    text-align: center;
    margin-left: 10px;
    margin-right: 12px; }
  .logo a.logo-normal {
    display: block;
    opacity: 1;
    padding: 11px 0 8px; }
  .logo p {
    float: left;
    font-size: 12px;
    margin: 10px 10px;
    color: #ffffff;
    line-height: 20px; }
  .logo .simple-text {
    text-transform: uppercase;
    padding: 0.5rem 0;
    display: block;
    white-space: nowrap;
    font-size: 14px;
    color: #ffffff;
    text-decoration: none;
    font-weight: 400;
    line-height: 30px;
    overflow: hidden; }

.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
  font-family: 'Avenir Next Medium';
  font-size: 16px; }
  .wrapper.wrapper-full-page {
    min-height: 100vh;
    height: auto; }

.sidebar,
.off-canvas-sidebar {
  position: fixed;
  top: 0;
  height: 100%;
  bottom: 0;
  width: 260px;
  left: 0;
  z-index: 1030;
  border-right: 1px solid #ddd;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .sidebar .sidebar-wrapper,
  .off-canvas-sidebar .sidebar-wrapper {
    position: relative;
    height: calc(100vh - 75px);
    overflow: auto;
    width: 260px;
    z-index: 4;
    padding-bottom: 100px; }
    .sidebar .sidebar-wrapper .dropdown .dropdown-backdrop,
    .off-canvas-sidebar .sidebar-wrapper .dropdown .dropdown-backdrop {
      display: none !important; }
    .sidebar .sidebar-wrapper .navbar-form,
    .off-canvas-sidebar .sidebar-wrapper .navbar-form {
      border: none; }
  .sidebar .sidebar-background,
  .off-canvas-sidebar .sidebar-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center; }
    .sidebar .sidebar-background:after,
    .off-canvas-sidebar .sidebar-background:after {
      position: absolute;
      z-index: 3;
      width: 100%;
      height: 100%;
      content: "";
      display: block;
      background: #ffffff;
      opacity: 1; }
  .sidebar:before, .sidebar:after,
  .off-canvas-sidebar:before,
  .off-canvas-sidebar:after {
    display: block;
    content: "";
    opacity: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  .sidebar:after,
  .off-canvas-sidebar:after {
    background: #66615B;
    background: linear-gradient(#66615B 0%, #000 80%);
    z-index: 3; }
  .sidebar[data-color="white"]:after,
  .off-canvas-sidebar[data-color="white"]:after {
    background: #ffffff; }
  .sidebar[data-color="white"] .nav li a,
  .sidebar[data-color="white"] .nav li a i,
  .sidebar[data-color="white"] .nav li a[data-toggle="collapse"],
  .sidebar[data-color="white"] .nav li a[data-toggle="collapse"] i,
  .sidebar[data-color="white"] .nav li a[data-toggle="collapse"] ~ div > ul > li .sidebar-mini-icon,
  .sidebar[data-color="white"] .nav li a[data-toggle="collapse"] ~ div > ul > li > a,
  .off-canvas-sidebar[data-color="white"] .nav li a,
  .off-canvas-sidebar[data-color="white"] .nav li a i,
  .off-canvas-sidebar[data-color="white"] .nav li a[data-toggle="collapse"],
  .off-canvas-sidebar[data-color="white"] .nav li a[data-toggle="collapse"] i,
  .off-canvas-sidebar[data-color="white"] .nav li a[data-toggle="collapse"] ~ div > ul > li .sidebar-mini-icon,
  .off-canvas-sidebar[data-color="white"] .nav li a[data-toggle="collapse"] ~ div > ul > li > a {
    color: #66615B;
    opacity: .7; }
  .sidebar[data-color="white"] .nav li:hover:not(.active) > a,
  .sidebar[data-color="white"] .nav li:focus:not(.active) > a,
  .off-canvas-sidebar[data-color="white"] .nav li:hover:not(.active) > a,
  .off-canvas-sidebar[data-color="white"] .nav li:focus:not(.active) > a {
    opacity: 1; }
  .sidebar[data-color="white"] .logo .simple-text,
  .off-canvas-sidebar[data-color="white"] .logo .simple-text {
    color: white; }
  .sidebar[data-color="white"] .logo:after,
  .off-canvas-sidebar[data-color="white"] .logo:after {
    background-color: white;
    opacity: .4; }
  .sidebar[data-color="white"] .user .info a span,
  .sidebar[data-color="white"] .user .nav .sidebar-mini-icon,
  .sidebar[data-color="white"] .user .nav .sidebar-normal,
  .off-canvas-sidebar[data-color="white"] .user .info a span,
  .off-canvas-sidebar[data-color="white"] .user .nav .sidebar-mini-icon,
  .off-canvas-sidebar[data-color="white"] .user .nav .sidebar-normal {
    color: #66615B !important; }
  .sidebar[data-color="white"] .user:after,
  .off-canvas-sidebar[data-color="white"] .user:after {
    background-color: #66615B;
    opacity: .4; }
  .sidebar[data-color="vabotu"]:after,
  .off-canvas-sidebar[data-color="vabotu"]:after {
    background: #241d3b; }

.sidebar,
.off-canvas-sidebar {
  position: fixed;
  top: 0;
  height: 100%;
  bottom: 0;
  width: 260px;
  left: 0;
  z-index: 1030;
  border-right: 1px solid #ddd;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }

.sidebar .sidebar-wrapper,
.off-canvas-sidebar .sidebar-wrapper {
  position: relative;
  height: calc(100vh - 75px);
  overflow: auto;
  width: 260px;
  z-index: 4;
  padding-bottom: 100px; }

.sidebar .sidebar-wrapper .dropdown .dropdown-backdrop,
.off-canvas-sidebar .sidebar-wrapper .dropdown .dropdown-backdrop {
  display: none !important; }

.sidebar .sidebar-wrapper .navbar-form,
.off-canvas-sidebar .sidebar-wrapper .navbar-form {
  border: none; }

.sidebar .navbar-minimize,
.off-canvas-sidebar .navbar-minimize {
  position: absolute;
  right: 20px;
  top: 2px;
  opacity: 1; }

.sidebar .logo-tim,
.off-canvas-sidebar .logo-tim {
  border-radius: 50%;
  border: 1px solid #333;
  display: block;
  height: 61px;
  width: 61px;
  float: left;
  overflow: hidden; }

.sidebar .logo-tim img,
.off-canvas-sidebar .logo-tim img {
  width: 60px;
  height: 60px; }

.sidebar .nav,
.off-canvas-sidebar .nav {
  margin-top: 20px;
  display: block; }

.sidebar .nav .caret,
.off-canvas-sidebar .nav .caret {
  top: 14px;
  position: absolute;
  right: 10px; }

.sidebar .nav li > a + div .nav li > a,
.off-canvas-sidebar .nav li > a + div .nav li > a {
  margin-top: 7px; }

.sidebar .nav li > a,
.off-canvas-sidebar .nav li > a {
  margin: 10px 15px 0;
  color: #9891b0;
  display: block;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  padding: 10px 8px;
  line-height: 30px;
  opacity: .7; }

.sidebar .nav li .nav > li > a,
.off-canvas-sidebar .nav li .nav > li > a {
  padding: 5px 8px; }

.sidebar .nav li.active > a,
.sidebar .nav li.active > a {
  opacity: 1;
  color: #fff;
  font-family: 'Avenir Next Bold'; }

.off-canvas-sidebar .nav li.active > a,
.off-canvas-sidebar .nav li.active > a > i {
  opacity: 1;
  color: #fff;
  font-family: 'Avenir Next Bold'; }

.sidebar .nav li:hover:not(.active) > a,
.off-canvas-sidebar .nav li:hover:not(.active) > a {
  opacity: 1;
  color: #fff; }
  .sidebar .nav li:hover:not(.active) > a .off-canvas-sidebar .nav li:focus:not(.active) > a,
  .sidebar .nav li:hover:not(.active) > a .sidebar .nav li:focus:not(.active) > a,
  .off-canvas-sidebar .nav li:hover:not(.active) > a .off-canvas-sidebar .nav li:focus:not(.active) > a,
  .off-canvas-sidebar .nav li:hover:not(.active) > a .sidebar .nav li:focus:not(.active) > a {
    font-family: 'Avenir Next Bold'; }

.sidebar .nav i,
.off-canvas-sidebar .nav i {
  float: left;
  margin-right: 12px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  position: relative; }

.sidebar .nav p,
.off-canvas-sidebar .nav p {
  margin-bottom: 0; }

.sidebar .nav .collapse .nav,
.sidebar .nav .collapsing .nav,
.off-canvas-sidebar .nav .collapse .nav,
.off-canvas-sidebar .nav .collapsing .nav {
  margin-top: 0; }

.sidebar .sidebar-background,
.off-canvas-sidebar .sidebar-background {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center; }

.sidebar .sidebar-background:after,
.off-canvas-sidebar .sidebar-background:after {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  content: "";
  display: block;
  background: #ffffff;
  opacity: 1; }

.sidebar .logo,
.off-canvas-sidebar .logo {
  position: relative;
  padding: 7px 0.8rem;
  z-index: 4; }

.sidebar .logo a.logo-mini,
.off-canvas-sidebar .logo a.logo-mini {
  opacity: 1;
  float: left;
  width: 34px;
  text-align: center;
  margin-left: 10px;
  margin-right: 12px; }

.sidebar .logo a.logo-normal,
.off-canvas-sidebar .logo a.logo-normal {
  display: block;
  opacity: 1;
  padding: 11px 0 8px;
  -webkit-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0); }

.sidebar .off-canvas-sidebar {
  content: '>';
  position: absolute;
  bottom: 0;
  right: 15px;
  height: 1px;
  width: calc(100% - 30px);
  background-color: rgba(255, 255, 255, 0.5); }

.sidebar .logo p,
.off-canvas-sidebar .logo p {
  float: left;
  font-size: 20px;
  margin: 10px 10px;
  color: #ffffff;
  line-height: 20px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; }

.sidebar .logo .simple-text,
.off-canvas-sidebar .logo .simple-text {
  text-transform: uppercase;
  padding: 0.5rem 0;
  display: block;
  white-space: nowrap;
  font-size: 1rem;
  color: #ffffff;
  text-decoration: none;
  font-weight: 400;
  line-height: 30px;
  overflow: hidden; }

.sidebar .logo-tim,
.off-canvas-sidebar .logo-tim {
  border-radius: 50%;
  border: 1px solid #333;
  display: block;
  height: 61px;
  width: 61px;
  float: left;
  overflow: hidden; }

.sidebar .logo-tim img,
.off-canvas-sidebar .logo-tim img {
  width: 60px;
  height: 60px; }

.sidebar:before, .sidebar:after,
.off-canvas-sidebar:before,
.off-canvas-sidebar:after {
  display: block;
  content: ">";
  opacity: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.sidebar:after,
.off-canvas-sidebar:after {
  background: #66615b;
  background: linear-gradient(#66615b 0%, #000 80%);
  z-index: 3; }

.navbar-wrapper {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center; }
  .navbar-wrapper .navbar-minimize {
    padding-right: 10px; }
    .navbar-wrapper .navbar-minimize .btn {
      margin: 0; }
  .navbar-wrapper .navbar-toggle .navbar-toggler {
    padding-left: 0; }
  .navbar-wrapper .navbar-toggle:hover .navbar-toggler-bar.bar2 {
    width: 22px; }

.navbar .navbar-wrapper {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center; }

.navbar .navbar-wrapper .navbar-minimize {
  padding-right: 10px; }

.navbar .navbar-wrapper .navbar-minimize .btn {
  margin: 0; }

.navbar .navbar-wrapper .navbar-toggle .navbar-toggler {
  padding-left: 0; }

.navbar .navbar-wrapper .navbar-toggle:hover .navbar-toggler-bar.bar2 {
  width: 22px; }

.user-panel {
  position: absolute;
  bottom: 0;
  color: white;
  z-index: 4;
  font-family: 'Avenir Next Demi Bold';
  width: 260px;
  -webkit-align-items: center;
          align-items: center;
  font-size: 15px; }
  .user-panel .dropdown-toggle::after {
    content: none; }
  .user-panel .dropdown {
    padding: 0; }
  .user-panel .dropdown-menu {
    left: 15px !important;
    font-family: 'Avenir Next Regular';
    font-size: 16px; }
  .user-panel .dropdown-item {
    color: #2e2748; }
  .user-panel .dropdown-item:hover {
    background-color: #2e2748; }

.user-img-sm {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  padding: 5px;
  float: left;
  cursor: 'pointer'; }

.navbar-toggler {
  z-index: 4;
  background-color: white;
  height: 30px;
  width: 30px;
  float: left;
  padding: 5px 5px;
  margin-top: 24px;
  margin-right: 19px;
  margin-left: 7px; }

.navbar-expand-lg .navbar-toggler {
  display: unset; }

.sidebar.closed {
  -webkit-transform: translateX(-70%);
          transform: translateX(-70%); }
  .sidebar.closed .main-panel {
    width: calc(100% - 80px) !important; }

.sidebar.open {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.navbar {
  padding: 0; }

.hide {
  opacity: 0 !important; }

.icn-right {
  float: right !important; }

.fa-home {
  font-size: 22px; }

.fa-envelope {
  font-size: 17px; }

.flex-x {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }

.flex-x.center {
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.flex-x.align-center {
  -webkit-align-items: center;
          align-items: center; }

.flex-y {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.flex-y.center {
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.flex-0 {
  -webkit-flex: 0 1;
          flex: 0 1; }

.flex-1, .list-flex ul {
  -webkit-flex: 1 1;
          flex: 1 1;
  min-width: 0;
  min-height: 0; }

.flex-wrap {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.full-height {
  height: 100vh; }

.full-height-64ex {
  height: calc(100vh - 64px); }

.fill-width {
  width: 100%; }

.scroll-x {
  overflow-x: auto; }

.pretty * {
  box-sizing: border-box; }

.pretty input:not([type='checkbox']):not([type='radio']) {
  display: none; }

.pretty {
  position: relative;
  display: inline-block;
  margin-right: 1em;
  white-space: nowrap;
  line-height: 1; }
  .pretty input {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 1em;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    margin: 0;
    padding: 0;
    cursor: pointer; }
  .pretty .state label {
    position: static;
    position: initial;
    display: inline-block;
    font-weight: normal;
    margin: 0;
    text-indent: 1.5em;
    min-width: calc(1em + 2px); }
    .pretty .state label:before, .pretty .state label:after {
      content: '';
      width: calc(1em + 2px);
      height: calc(1em + 2px);
      display: block;
      box-sizing: border-box;
      border-radius: 0;
      border: 1px solid transparent;
      z-index: 0;
      position: absolute;
      left: 0;
      top: calc((0% - (100% - 1em)) - 8%);
      background-color: transparent; }
    .pretty .state label:before {
      border-color: #bdc3c7; }
  .pretty .state.p-is-hover, .pretty .state.p-is-indeterminate {
    display: none; }

@-webkit-keyframes zoom {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0); } }

@keyframes zoom {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0); } }

@-webkit-keyframes tada {
  0% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
    -webkit-transform: scale(7);
            transform: scale(7); }
  38% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  55% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  72% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-transform: scale(1);
            transform: scale(1); }
  81% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transform: scale(1.24);
            transform: scale(1.24); }
  89% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-transform: scale(1);
            transform: scale(1); }
  95% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transform: scale(1.04);
            transform: scale(1.04); }
  100% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes tada {
  0% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
    -webkit-transform: scale(7);
            transform: scale(7); }
  38% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  55% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  72% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-transform: scale(1);
            transform: scale(1); }
  81% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transform: scale(1.24);
            transform: scale(1.24); }
  89% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-transform: scale(1);
            transform: scale(1); }
  95% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transform: scale(1.04);
            transform: scale(1.04); }
  100% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes jelly {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1); }
  40% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1); }
  50% {
    -webkit-transform: scale3d(0.85, 1.15, 1);
            transform: scale3d(0.85, 1.15, 1); }
  65% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1); }
  75% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); } }

@keyframes jelly {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1); }
  40% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1); }
  50% {
    -webkit-transform: scale3d(0.85, 1.15, 1);
            transform: scale3d(0.85, 1.15, 1); }
  65% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1); }
  75% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); } }

@-webkit-keyframes rotate {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(-200px) rotate(-45deg);
            transform: translateZ(-200px) rotate(-45deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(0) rotate(0);
            transform: translateZ(0) rotate(0); } }

@keyframes rotate {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(-200px) rotate(-45deg);
            transform: translateZ(-200px) rotate(-45deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(0) rotate(0);
            transform: translateZ(0) rotate(0); } }

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0px 0px 0px 0px #bdc3c7; }
  100% {
    box-shadow: 0px 0px 0px 1.5em rgba(189, 195, 199, 0); } }

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 0px 0px #bdc3c7; }
  100% {
    box-shadow: 0px 0px 0px 1.5em rgba(189, 195, 199, 0); } }

.pretty.p-default.p-fill .state label:after {
  -webkit-transform: scale(1);
          transform: scale(1); }

.pretty.p-default .state label:after {
  -webkit-transform: scale(0.6);
          transform: scale(0.6); }

.pretty.p-default input:checked ~ .state label:after {
  background-color: #bdc3c7 !important; }

.pretty.p-default.p-thick .state label:before, .pretty.p-default.p-thick .state label:after {
  border-width: calc(1em / 7); }

.pretty.p-default.p-thick .state label:after {
  -webkit-transform: scale(0.4) !important;
          transform: scale(0.4) !important; }

.pretty.p-icon .state .icon {
  position: absolute;
  font-size: 1em;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  left: 0;
  z-index: 1;
  text-align: center;
  line-height: normal;
  top: calc((0% - (100% - 1em)) - 8%);
  border: 1px solid transparent;
  opacity: 0; }

.pretty.p-icon .state .icon:before {
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  line-height: 1; }

.pretty.p-icon input:checked ~ .state .icon {
  opacity: 1; }

.pretty.p-icon input:checked ~ .state label:before {
  border-color: #5a656b; }

.pretty.p-svg .state .svg {
  position: absolute;
  font-size: 1em;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  left: 0;
  z-index: 1;
  text-align: center;
  line-height: normal;
  top: calc((0% - (100% - 1em)) - 8%);
  border: 1px solid transparent;
  opacity: 0; }

.pretty.p-svg .state svg {
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  line-height: 1; }

.pretty.p-svg input:checked ~ .state .svg {
  opacity: 1; }

.pretty.p-image .state img {
  opacity: 0;
  position: absolute;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  top: 0;
  top: calc((0% - (100% - 1em)) - 8%);
  left: 0;
  z-index: 0;
  text-align: center;
  line-height: normal;
  -webkit-transform: scale(0.8);
          transform: scale(0.8); }

.pretty.p-image input:checked ~ .state img {
  opacity: 1; }

.pretty.p-switch input {
  min-width: 2em; }

.pretty.p-switch .state {
  position: relative; }
  .pretty.p-switch .state:before {
    content: '';
    border: 1px solid #bdc3c7;
    border-radius: 60px;
    width: 2em;
    box-sizing: unset;
    height: calc(1em + 2px);
    position: absolute;
    top: 0;
    top: calc((0% - (100% - 1em)) - 16%);
    z-index: 0;
    transition: all 0.5s ease; }
  .pretty.p-switch .state label {
    text-indent: 2.5em; }
    .pretty.p-switch .state label:before, .pretty.p-switch .state label:after {
      transition: all 0.5s ease;
      border-radius: 100%;
      left: 0;
      border-color: transparent;
      -webkit-transform: scale(0.8);
              transform: scale(0.8); }
    .pretty.p-switch .state label:after {
      background-color: #bdc3c7 !important; }

.pretty.p-switch input:checked ~ .state:before {
  border-color: #5a656b; }

.pretty.p-switch input:checked ~ .state label:before {
  opacity: 0; }

.pretty.p-switch input:checked ~ .state label:after {
  background-color: #5a656b !important;
  left: 1em; }

.pretty.p-switch.p-fill input:checked ~ .state:before {
  border-color: #5a656b;
  background-color: #5a656b !important; }

.pretty.p-switch.p-fill input:checked ~ .state label:before {
  opacity: 0; }

.pretty.p-switch.p-fill input:checked ~ .state label:after {
  background-color: #fff !important;
  left: 1em; }

.pretty.p-switch.p-slim .state:before {
  height: 0.1em;
  background: #bdc3c7 !important;
  top: calc(50% - 0.1em); }

.pretty.p-switch.p-slim input:checked ~ .state:before {
  border-color: #5a656b;
  background-color: #5a656b !important; }

.pretty.p-has-hover input:hover ~ .state:not(.p-is-hover) {
  display: none; }

.pretty.p-has-hover input:hover ~ .state.p-is-hover {
  display: block; }
  .pretty.p-has-hover input:hover ~ .state.p-is-hover .icon {
    display: block; }

.pretty.p-has-focus input:focus ~ .state label:before {
  box-shadow: 0px 0px 3px 0px #bdc3c7; }

.pretty.p-has-indeterminate input[type='checkbox']:indeterminate ~ .state:not(.p-is-indeterminate) {
  display: none; }

.pretty.p-has-indeterminate input[type='checkbox']:indeterminate ~ .state.p-is-indeterminate {
  display: block; }
  .pretty.p-has-indeterminate input[type='checkbox']:indeterminate ~ .state.p-is-indeterminate .icon {
    display: block;
    opacity: 1; }

.pretty.p-toggle .state.p-on {
  opacity: 0;
  display: none; }

.pretty.p-toggle .state.p-off,
.pretty.p-toggle .state .icon,
.pretty.p-toggle .state .svg,
.pretty.p-toggle .state img {
  opacity: 1;
  display: inherit; }

.pretty.p-toggle .state.p-off .icon {
  color: #bdc3c7; }

.pretty.p-toggle input:checked ~ .state.p-on {
  opacity: 1;
  display: inherit; }

.pretty.p-toggle input:checked ~ .state.p-off {
  opacity: 0;
  display: none; }

.pretty.p-plain input:checked ~ .state label:before,
.pretty.p-plain.p-toggle .state label:before {
  content: none; }

.pretty.p-plain.p-plain .icon {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

.pretty.p-round .state label:before, .pretty.p-round .state label:after {
  border-radius: 100%; }

.pretty.p-round.p-icon .state .icon {
  border-radius: 100%;
  overflow: hidden; }
  .pretty.p-round.p-icon .state .icon:before {
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }

.pretty.p-curve .state label:before, .pretty.p-curve .state label:after {
  border-radius: 20%; }

.pretty.p-smooth label:before,
.pretty.p-smooth label:after,
.pretty.p-smooth .icon,
.pretty.p-smooth .svg {
  transition: all 0.5s ease; }

.pretty.p-smooth input:checked + .state label:after {
  transition: all 0.3s ease; }

.pretty.p-smooth input:checked + .state .icon,
.pretty.p-smooth input:checked + .state .svg,
.pretty.p-smooth input:checked + .state img {
  -webkit-animation: zoom 0.2s ease;
          animation: zoom 0.2s ease; }

.pretty.p-smooth.p-default input:checked + .state label:after {
  -webkit-animation: zoom 0.2s ease;
          animation: zoom 0.2s ease; }

.pretty.p-smooth.p-plain input:checked + .state label:before {
  content: '';
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: all 0.5s ease; }

.pretty.p-tada:not(.p-default) input:checked + .state .icon,
.pretty.p-tada:not(.p-default) input:checked + .state .svg,
.pretty.p-tada:not(.p-default) input:checked + .state img,
.pretty.p-tada:not(.p-default) input:checked + .state label:before,
.pretty.p-tada:not(.p-default) input:checked + .state label:after {
  -webkit-animation: tada 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1 alternate;
          animation: tada 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1 alternate;
  opacity: 1; }

.pretty.p-jelly:not(.p-default) input:checked + .state .icon,
.pretty.p-jelly:not(.p-default) input:checked + .state .svg,
.pretty.p-jelly:not(.p-default) input:checked + .state img,
.pretty.p-jelly:not(.p-default) input:checked + .state label:before,
.pretty.p-jelly:not(.p-default) input:checked + .state label:after {
  -webkit-animation: jelly 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          animation: jelly 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }

.pretty.p-jelly:not(.p-default) input:checked + .state label:before {
  border-color: transparent; }

.pretty.p-rotate:not(.p-default) input:checked ~ .state .icon,
.pretty.p-rotate:not(.p-default) input:checked ~ .state .svg,
.pretty.p-rotate:not(.p-default) input:checked ~ .state img,
.pretty.p-rotate:not(.p-default) input:checked ~ .state label:before,
.pretty.p-rotate:not(.p-default) input:checked ~ .state label:after {
  -webkit-animation: rotate 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          animation: rotate 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }

.pretty.p-rotate:not(.p-default) input:checked ~ .state label:before {
  border-color: transparent; }

.pretty.p-pulse:not(.p-switch) input:checked ~ .state label:before {
  -webkit-animation: pulse 1s;
          animation: pulse 1s; }

.pretty input[disabled] {
  cursor: not-allowed;
  display: none; }
  .pretty input[disabled] ~ * {
    opacity: .5; }

.pretty.p-locked input {
  display: none;
  cursor: not-allowed; }

.pretty input:checked ~ .state.p-primary label:after,
.pretty.p-toggle .state.p-primary label:after {
  background-color: #428bca !important; }

.pretty input:checked ~ .state.p-primary .icon,
.pretty input:checked ~ .state.p-primary .svg,
.pretty.p-toggle .state.p-primary .icon,
.pretty.p-toggle .state.p-primary .svg {
  color: #fff;
  stroke: #fff; }

.pretty input:checked ~ .state.p-primary-o label:before,
.pretty.p-toggle .state.p-primary-o label:before {
  border-color: #428bca; }

.pretty input:checked ~ .state.p-primary-o label:after,
.pretty.p-toggle .state.p-primary-o label:after {
  background-color: transparent; }

.pretty input:checked ~ .state.p-primary-o .icon,
.pretty input:checked ~ .state.p-primary-o .svg,
.pretty input:checked ~ .state.p-primary-o svg,
.pretty.p-toggle .state.p-primary-o .icon,
.pretty.p-toggle .state.p-primary-o .svg,
.pretty.p-toggle .state.p-primary-o svg {
  color: #428bca;
  stroke: #428bca; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-primary-o label:after {
  background-color: #428bca !important; }

.pretty.p-switch input:checked ~ .state.p-primary:before {
  border-color: #428bca; }

.pretty.p-switch.p-fill input:checked ~ .state.p-primary:before {
  background-color: #428bca !important; }

.pretty.p-switch.p-slim input:checked ~ .state.p-primary:before {
  border-color: #245682;
  background-color: #245682 !important; }

.pretty input:checked ~ .state.p-info label:after,
.pretty.p-toggle .state.p-info label:after {
  background-color: #5bc0de !important; }

.pretty input:checked ~ .state.p-info .icon,
.pretty input:checked ~ .state.p-info .svg,
.pretty.p-toggle .state.p-info .icon,
.pretty.p-toggle .state.p-info .svg {
  color: #fff;
  stroke: #fff; }

.pretty input:checked ~ .state.p-info-o label:before,
.pretty.p-toggle .state.p-info-o label:before {
  border-color: #5bc0de; }

.pretty input:checked ~ .state.p-info-o label:after,
.pretty.p-toggle .state.p-info-o label:after {
  background-color: transparent; }

.pretty input:checked ~ .state.p-info-o .icon,
.pretty input:checked ~ .state.p-info-o .svg,
.pretty input:checked ~ .state.p-info-o svg,
.pretty.p-toggle .state.p-info-o .icon,
.pretty.p-toggle .state.p-info-o .svg,
.pretty.p-toggle .state.p-info-o svg {
  color: #5bc0de;
  stroke: #5bc0de; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-info-o label:after {
  background-color: #5bc0de !important; }

.pretty.p-switch input:checked ~ .state.p-info:before {
  border-color: #5bc0de; }

.pretty.p-switch.p-fill input:checked ~ .state.p-info:before {
  background-color: #5bc0de !important; }

.pretty.p-switch.p-slim input:checked ~ .state.p-info:before {
  border-color: #2390b0;
  background-color: #2390b0 !important; }

.pretty input:checked ~ .state.p-success label:after,
.pretty.p-toggle .state.p-success label:after {
  background-color: #5cb85c !important; }

.pretty input:checked ~ .state.p-success .icon,
.pretty input:checked ~ .state.p-success .svg,
.pretty.p-toggle .state.p-success .icon,
.pretty.p-toggle .state.p-success .svg {
  color: #fff;
  stroke: #fff; }

.pretty input:checked ~ .state.p-success-o label:before,
.pretty.p-toggle .state.p-success-o label:before {
  border-color: #5cb85c; }

.pretty input:checked ~ .state.p-success-o label:after,
.pretty.p-toggle .state.p-success-o label:after {
  background-color: transparent; }

.pretty input:checked ~ .state.p-success-o .icon,
.pretty input:checked ~ .state.p-success-o .svg,
.pretty input:checked ~ .state.p-success-o svg,
.pretty.p-toggle .state.p-success-o .icon,
.pretty.p-toggle .state.p-success-o .svg,
.pretty.p-toggle .state.p-success-o svg {
  color: #5cb85c;
  stroke: #5cb85c; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-success-o label:after {
  background-color: #5cb85c !important; }

.pretty.p-switch input:checked ~ .state.p-success:before {
  border-color: #5cb85c; }

.pretty.p-switch.p-fill input:checked ~ .state.p-success:before {
  background-color: #5cb85c !important; }

.pretty.p-switch.p-slim input:checked ~ .state.p-success:before {
  border-color: #357935;
  background-color: #357935 !important; }

.pretty input:checked ~ .state.p-warning label:after,
.pretty.p-toggle .state.p-warning label:after {
  background-color: #f0ad4e !important; }

.pretty input:checked ~ .state.p-warning .icon,
.pretty input:checked ~ .state.p-warning .svg,
.pretty.p-toggle .state.p-warning .icon,
.pretty.p-toggle .state.p-warning .svg {
  color: #fff;
  stroke: #fff; }

.pretty input:checked ~ .state.p-warning-o label:before,
.pretty.p-toggle .state.p-warning-o label:before {
  border-color: #f0ad4e; }

.pretty input:checked ~ .state.p-warning-o label:after,
.pretty.p-toggle .state.p-warning-o label:after {
  background-color: transparent; }

.pretty input:checked ~ .state.p-warning-o .icon,
.pretty input:checked ~ .state.p-warning-o .svg,
.pretty input:checked ~ .state.p-warning-o svg,
.pretty.p-toggle .state.p-warning-o .icon,
.pretty.p-toggle .state.p-warning-o .svg,
.pretty.p-toggle .state.p-warning-o svg {
  color: #f0ad4e;
  stroke: #f0ad4e; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-warning-o label:after {
  background-color: #f0ad4e !important; }

.pretty.p-switch input:checked ~ .state.p-warning:before {
  border-color: #f0ad4e; }

.pretty.p-switch.p-fill input:checked ~ .state.p-warning:before {
  background-color: #f0ad4e !important; }

.pretty.p-switch.p-slim input:checked ~ .state.p-warning:before {
  border-color: #c77c11;
  background-color: #c77c11 !important; }

.pretty input:checked ~ .state.p-danger label:after,
.pretty.p-toggle .state.p-danger label:after {
  background-color: #d9534f !important; }

.pretty input:checked ~ .state.p-danger .icon,
.pretty input:checked ~ .state.p-danger .svg,
.pretty.p-toggle .state.p-danger .icon,
.pretty.p-toggle .state.p-danger .svg {
  color: #fff;
  stroke: #fff; }

.pretty input:checked ~ .state.p-danger-o label:before,
.pretty.p-toggle .state.p-danger-o label:before {
  border-color: #d9534f; }

.pretty input:checked ~ .state.p-danger-o label:after,
.pretty.p-toggle .state.p-danger-o label:after {
  background-color: transparent; }

.pretty input:checked ~ .state.p-danger-o .icon,
.pretty input:checked ~ .state.p-danger-o .svg,
.pretty input:checked ~ .state.p-danger-o svg,
.pretty.p-toggle .state.p-danger-o .icon,
.pretty.p-toggle .state.p-danger-o .svg,
.pretty.p-toggle .state.p-danger-o svg {
  color: #d9534f;
  stroke: #d9534f; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-danger-o label:after {
  background-color: #d9534f !important; }

.pretty.p-switch input:checked ~ .state.p-danger:before {
  border-color: #d9534f; }

.pretty.p-switch.p-fill input:checked ~ .state.p-danger:before {
  background-color: #d9534f !important; }

.pretty.p-switch.p-slim input:checked ~ .state.p-danger:before {
  border-color: #a02622;
  background-color: #a02622 !important; }

.pretty.p-bigger label:before,
.pretty.p-bigger label:after,
.pretty.p-bigger .icon,
.pretty.p-bigger .svg,
.pretty.p-bigger .img {
  font-size: 1.2em !important;
  top: calc((0% - (100% - 1em)) - 35%) !important; }

.pretty.p-bigger label {
  text-indent: 1.7em; }

@media print {
  .pretty .state:before,
  .pretty .state label:before,
  .pretty .state label:after,
  .pretty .state .icon {
    color-adjust: exact;
    /* stylelint-disable */
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact; } }

.flex-x {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }

.flex-x.center {
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.flex-x.align-center {
  -webkit-align-items: center;
          align-items: center; }

.flex-y {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.flex-y.center {
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.flex-0 {
  -webkit-flex: 0 1;
          flex: 0 1; }

.flex-1, .list-flex ul {
  -webkit-flex: 1 1;
          flex: 1 1;
  min-width: 0;
  min-height: 0; }

.flex-wrap {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.full-height {
  height: 100vh; }

.full-height-64ex {
  height: calc(100vh - 64px); }

.fill-width {
  width: 100%; }

.scroll-x {
  overflow-x: auto; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff; }

.custom-dropdown-button .dropdown-toggle,
.custom-dropdown-button .btn.btn-secondary.btn-sm {
  border: 1px solid #bfcfe0;
  text-transform: uppercase;
  font-size: 10.4px;
  padding: 5px 15px; }

.custom-dropdown button {
  text-transform: uppercase;
  font-size: 10.4px; }

.search-box {
  border: 1px solid #bfcfe0;
  font-size: 12px;
  color: #92aac4;
  background-color: #f8f9fb;
  font-family: 'Avenir Next', fontAwesome !important; }

.w-250 {
  width: 250px; }

.selectbox {
  padding: 2px; }

.btn-delete {
  background-color: #dc004a;
  border-radius: 50px; }

.th {
  cursor: pointer; }
  .th i {
    padding-left: 5px;
    color: #7e98b5; }

table {
  font-size: 12px; }

.react-bs-table-csv-btn {
  border: 0;
  font-size: 12px; }

table tbody,
td {
  font-family: 'Avenir Next Regular'; }

.table-link {
  color: #1f3bfc !important; }
  .table-link:hover {
    font-style: bold;
    cursor: pointer; }

.word-break {
  word-break: break-all; }

button span {
  font-family: 'Avenir Next Demi Bold'; }

.table th,
.table td {
  border-top: 0;
  font-family: 'Avenir Next Medium'; }

.clear-button {
  margin-left: -35px !important;
  z-index: 100;
  border: none;
  background-color: inherit;
  text-indent: 0%; }

.clear-button-black {
  margin-left: -50px !important;
  z-index: 100;
  border: none;
  text-indent: 0%;
  color: black; }
  .clear-button-black:hover, .clear-button-black:active {
    color: black; }

.custom-menu-pad {
  padding: 0.25rem 1.5rem;
  color: black; }

.module-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.custom-react-table-theme-class .rt-tbody .rt-td {
  font-family: 'Avenir Next Regular';
  color: #757575; }

.Table__itemCount {
  font-size: 14px; }

.Table__pagination {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 20px 10px 0px; }

.Table__pageButton {
  font-size: 18px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #757575 !important;
  margin: 0 5px; }

.Table__pageButton:disabled {
  cursor: not-allowed;
  color: gray; }

.Table__pageButton--active {
  font-weight: bold;
  background: black;
  color: white !important;
  width: 30px;
  height: 30px;
  border-radius: 6px; }

.tabl-search:focus {
  outline: 0; }

.back-icon {
  position: absolute;
  right: 15px;
  bottom: 20px;
  color: black; }

.-sort-desc {
  box-shadow: none !important; }
  .-sort-desc:before {
    content: "\25BC";
    float: right;
    margin-right: 14px;
    color: black; }

.-sort-asc {
  box-shadow: none !important; }
  .-sort-asc:before {
    content: "\25B2";
    float: right;
    margin-right: 14px;
    color: black; }

.perfect-scrollbar-on .sidebar,
.perfect-scrollbar-on .main-panel {
  height: 100%;
  max-height: 100%; }

.error {
  color: red; }

.success {
  color: green; }

.main-panel,
.sidebar {
  transition: all 0.2s cubic-bezier(0.685, 0.0473, 0.346, 1); }

.height-max-content {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }

.hovered:hover {
  font-size: 13.5px;
  cursor: pointer; }

.main-panel {
  font-family: 'Avenir Next Medium';
  position: relative;
  float: right;
  height: auto;
  background-color: #f8f9fb;
  font-size: 13px;
  color: black; }
  .main-panel > .content {
    padding: 0 30px 30px;
    min-height: calc(100vh - 123px);
    margin-top: 43px; }
  .main-panel > .navbar {
    margin-bottom: 0; }
  .main-panel .header {
    margin-bottom: 50px; }

.dropdown {
  padding: 0 5px; }

.title {
  text-transform: uppercase;
  margin: 5px; }

.title.f-20 {
  font-size: 20px; }

.card {
  color: #92aac4;
  border: 0; }

p,
.form-check-label {
  margin: 3px; }

.count {
  color: #66615b; }

.showCalender.show {
  display: block; }

.showCalender.hide {
  display: none; }

.showCalender button {
  margin: 2px 37px; }

.showCalender input {
  text-align: center; }

.day-box {
  display: unset;
  width: 50px; }

.hovered:hover {
  font-size: 13.5px;
  cursor: pointer; }

a,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: black; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-primary-o label:after {
  background-color: black !important; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-success-o label:after {
  background-color: #1f89fc !important; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-info-o label:after {
  background-color: #64b9fc !important; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-danger-o label:after {
  background-color: #a9c6f9 !important; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-warning-o label:after {
  background-color: #48d2ec !important; }

.pretty input:checked ~ .state.p-primary-o label:before,
.pretty.p-toggle .state.p-primary-o label:before {
  border-color: black; }

.pretty input:checked ~ .state.p-success-o label:before,
.pretty.p-toggle .state.p-success-o label:before {
  border-color: #1f89fc; }

.pretty input:checked ~ .state.p-info-o label:before,
.pretty.p-toggle .state.p-info-o label:before {
  border-color: #64b9fc; }

.pretty input:checked ~ .state.p-danger-o label:before,
.pretty.p-toggle .state.p-danger-o label:before {
  border-color: #a9c6f9; }

.pretty input:checked ~ .state.p-warning-o label:before,
.pretty.p-toggle .state.p-warning-o label:before {
  border-color: #48d2ec; }

.pretty .state label:before, .pretty .state label:after {
  width: 12px;
  height: 11.5px; }

.pretty .state label {
  text-indent: 0.75em; }

.pretty {
  margin-bottom: 10px; }

.text-black {
  color: black; }

.header-button button {
  text-transform: uppercase;
  font-size: 10.4px;
  padding: 5px; }

.user-img-lg {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  padding: 5px;
  float: left;
  cursor: pointer; }

.line {
  border-color: #bfcfe0; }

.form-control {
  font-size: 14px;
  color: #92aac4;
  font-family: 'Avenir Next Medium'; }
  .form-control:focus {
    color: #92aac4; }

.f-12 {
  font-size: 12px; }

.btn-secondary {
  background-color: #596b7f;
  border-color: #596b7f; }

.btn {
  margin: 2px; }

.expand-button {
  padding: 1px; }

.mr-3 {
  margin-right: 0px; }

label a.active {
  color: black !important; }

label a,
label a:visited {
  text-decoration: none;
  color: #596b7f; }

label a:hover,
label a:active {
  text-decoration: none;
  font-family: 'Avenir Next Demi Bold';
  color: black; }

.custom-input {
  border: 1px solid #d2e1f1;
  border-radius: 6px;
  background-color: white;
  white-space: nowrap; }
  .custom-input label {
    color: #92aac4;
    font-size: 14px;
    margin: 0;
    padding-left: 15px; }
  .custom-input .form-control {
    border: none;
    padding-left: 2%;
    color: black; }

tbody.collapse.in {
  display: table-row-group; }

.dropdown-menu {
  font-size: 12px;
  font-family: 'Avenir Next';
  min-width: 6rem;
  left: -14px !important; }

.btn-success {
  background-color: #00c486;
  border-color: #00c486; }
  .btn-success:hover {
    background-color: #03b980;
    border-color: #03b980; }

.btn-sm {
  font-size: 12px; }

.form-label {
  font-family: 'Avenir Next Regular'; }

.regular, .footer {
  font-family: 'Avenir Next Regular'; }

.react-datepicker-popper {
  left: -30px !important; }

.hidden {
  display: none; }

.d-down-style {
  position: absolute;
  will-change: transform;
  top: 15px;
  left: 0px;
  -webkit-transform: translate3d(0px, 21px, 0px);
          transform: translate3d(0px, 21px, 0px);
  min-width: 11rem;
  left: -6px !important; }

.footer {
  text-transform: uppercase;
  color: #727989;
  font-size: 13px; }
  .footer span {
    font-family: 'Avenir Next Bold'; }
  .footer a {
    text-decoration: underline;
    color: #727989; }

.ckeditor {
  height: 100%; }

.btn:focus,
.btn.focus {
  box-shadow: none; }

.form-control:focus,
.form-control.focus {
  box-shadow: none; }

.dropdown-item:hover {
  background-color: #007bff;
  color: white; }

.selected {
  background-color: #007bff;
  color: white !important; }
  .selected::after {
    padding-left: 10px;
    content: '\F00C';
    font-family: fontAwesome; }

.link:visited {
  text-decoration: none;
  color: #596b7f; }

.link:hover, .link:active {
  text-decoration: none;
  font-family: 'Avenir Next Demi Bold';
  color: black;
  cursor: pointer; }

.link-active {
  text-decoration: none;
  font-family: 'Avenir Next Demi Bold';
  color: black !important; }

.form-control:disabled,
.form-control[readonly] {
  background-color: white !important; }

span.msg {
  color: #03b980; }

.bg-vabotu-gradient {
  background: #6441a5;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #2a0845, #6441a5);
  /* Chrome 10-25, Safari 5.1-6 */
  background-image: linear-gradient(to right, #4c49c8, #4641aa, #40398e, #383172, #302958);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e73f7c', endColorstr='#d81b60', GradientType=0 ) !important;
  color: #fff; }

.error {
  color: red; }

.wrapper {
  font-size: 13px; }

/*
 * Page: Login & Register
 * ----------------------
 */
.login-logo {
  font-size: 35px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 300; }

.login-logo a {
  color: #444; }

.login-page {
  position: relative;
  top: 0;
  height: 100vh;
  font-family: 'Avenir Next Medium';
  font-size: 16px; }

.login-box {
  width: 360px;
  margin: auto; }

@media (max-width: 768px) {
  .login-box {
    width: 90%;
    margin-top: 20px; } }

.login-box-body {
  background: #fff;
  padding: 20px;
  border-top: 0;
  color: #666; }

.login-box-body .form-control-feedback {
  color: #777; }

.login-box-msg {
  margin: 0;
  text-align: center;
  padding: 0 20px 20px 20px; }

.social-auth-links {
  margin: 10px 0; }

body {
  font-family: 'Avenir Next Regular'; }

