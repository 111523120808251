@import './helper/flex.scss';
@import '~pretty-checkbox/src/pretty-checkbox.scss';
@import './helper/_table';

.perfect-scrollbar-on {
	.sidebar,
	.main-panel {
		height: 100%;
		max-height: 100%;
	}
}

.error {
	color: red;
}

.success {
	color: green;
}

.main-panel,
.sidebar {
	@include transition (0.20s, cubic-bezier(0.685, 0.0473, 0.346, 1));
}

.height-max-content {
	height: fit-content;
}

.hovered {
	&:hover {
		font-size: 13.5px;
		cursor: pointer;
	}
}

.main-panel {
	font-family: 'Avenir Next Medium';
	position: relative;
	float: right;
	// width: $sidebar-width;
	height: auto;
	background-color: #f8f9fb;
	font-size: 13px;
	color: black;

	> .content {
		padding: 0 30px 30px;
		min-height: calc(100vh - 123px);
		margin-top: 43px;
	}

	> .navbar {
		margin-bottom: 0;
	}

	.header {
		margin-bottom: 50px;
	}
}

.dropdown {
	padding: 0 5px;
}

// input[type="checkbox"] {
// 	-webkit-appearance: radio; /* Chrome, Safari, Opera */
// 	-moz-appearance: radio; /* Firefox */
// 	-ms-appearance: radio; /* not currently supported */
// }

.title {
	// font-size: $font-size-medium;
	text-transform: uppercase;
	margin: 5px;
}

.title.f-20 {
	font-size: 20px;
}
.card {
	color: $dark-gray;
	border: 0;
}

p,
.form-check-label {
	margin: 3px;
}

.count {
	color: $font-color;
}

.showCalender.show {
	display: block;
}

.showCalender.hide {
	display: none;
}

.showCalender button {
	margin: 2px 37px;
}

.showCalender input {
	text-align: center;
}

.day-box {
	display: unset;
	width: 50px;
}

.hovered:hover {
	font-size: 13.5px;
	cursor: pointer;
}

a,
a:visited,
a:hover,
a:active {
	text-decoration: none;
	color: black;
}

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-primary-o label:after {
	background-color: black !important;
}

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-success-o label:after {
	background-color: #1f89fc !important;
}

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-info-o label:after {
	background-color: #64b9fc !important;
}

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-danger-o label:after {
	background-color: #a9c6f9 !important;
}

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-warning-o label:after {
	background-color: #48d2ec !important;
}

.pretty input:checked ~ .state.p-primary-o label:before,
.pretty.p-toggle .state.p-primary-o label:before {
	border-color: black;
}

.pretty input:checked ~ .state.p-success-o label:before,
.pretty.p-toggle .state.p-success-o label:before {
	border-color: #1f89fc;
}

.pretty input:checked ~ .state.p-info-o label:before,
.pretty.p-toggle .state.p-info-o label:before {
	border-color: #64b9fc;
}

.pretty input:checked ~ .state.p-danger-o label:before,
.pretty.p-toggle .state.p-danger-o label:before {
	border-color: #a9c6f9;
}

.pretty input:checked ~ .state.p-warning-o label:before,
.pretty.p-toggle .state.p-warning-o label:before {
	border-color: #48d2ec;
}

.pretty .state label:before,
.pretty .state label:after {
	width: 12px;
	height: 11.5px;
}

.pretty .state label {
	text-indent: 0.75em;
}
.pretty {
	margin-bottom: 10px;
}

.text-black {
	color: black;
}
.header-button button {
	text-transform: uppercase;
	font-size: 10.4px;
	padding: 5px;
}

.user-img-lg {
	height: 150px;
	width: 150px;
	border-radius: 50%;
	padding: 5px;
	float: left;
	cursor: pointer;
}
.line {
	border-color: #bfcfe0;
}

.form-control {
	font-size: 14px;
	color: #92aac4;
	&:focus {
		color: #92aac4;
	}
	font-family: 'Avenir Next Medium';
}

.f-12 {
	font-size: 12px;
}

.btn-secondary {
	background-color: #596b7f;
	border-color: #596b7f;
}

.btn {
	margin: 2px;
}

.expand-button {
	padding: 1px;
}

.mr-3 {
	margin-right: 0px;
}

label {
	a.active {
		@extend .demi-bold;
		color: black !important;
	}
	a,
	a:visited {
		text-decoration: none;
		color: #596b7f;
	}
	a:hover,
	a:active {
		text-decoration: none;
		font-family: 'Avenir Next Demi Bold';
		color: black;
	}
}

.custom-input {
	border: 1px solid #d2e1f1;
	border-radius: 6px;
	background-color: white;
	white-space: nowrap;
	label {
		color: #92aac4;
		font-size: 14px;
		margin: 0;
		padding-left: 15px;
	}
	.form-control {
		border: none;
		padding-left: 2%;
		color: black;
		@extend .demi-bold;
	}
}

tbody.collapse.in {
	display: table-row-group;
}

.dropdown-menu {
	font-size: 12px;
	font-family: 'Avenir Next';
	min-width: 6rem;
	left: -14px !important;
}

.btn-success {
	background-color: #00c486;
	border-color: #00c486;
	&:hover {
		background-color: #03b980;
		border-color: #03b980;
	}
}

.btn-sm {
	// padding: 4px 15px;
	font-size: 12px;
}

.form-label {
	font-family: 'Avenir Next Regular';
}

.regular {
	font-family: 'Avenir Next Regular';
}

.react-datepicker-popper {
	left: -30px !important;
}

.hidden {
	display: none;
}

.d-down-style {
	position: absolute;
	will-change: transform;
	top: 15px;
	left: 0px;
	transform: translate3d(0px, 21px, 0px);
	min-width: 11rem;
	left: -6px !important;
}

.footer {
	text-transform: uppercase;
	color: #727989;
	font-size: 13px;
	@extend .regular;

	span {
		font-family: 'Avenir Next Bold';
	}

	a {
		text-decoration: underline;
		color: #727989;
	}
}

.ckeditor {
	height: 100%;
}

.btn:focus,
.btn.focus {
	box-shadow: none;
}

.form-control:focus,
.form-control.focus {
	box-shadow: none;
}

.dropdown-item {
	&:hover {
		background-color: #007bff;
		color: white;
	}
}

.selected {
	background-color: #007bff;
	color: white !important;
	&::after {
		padding-left: 10px;
		content: '\f00c';
		font-family: fontAwesome;
	}
}

.link {
	&:visited {
		text-decoration: none;
		color: #596b7f;
	}
	&:hover,
	&:active {
		text-decoration: none;
		font-family: 'Avenir Next Demi Bold';
		color: black;
		cursor: pointer;
	}
}

.link-active {
	text-decoration: none;
	font-family: 'Avenir Next Demi Bold';
	color: black !important;
}

.form-control:disabled,
.form-control[readonly] {
	background-color: white !important;
}

span.msg {
	color: #03b980;
}
