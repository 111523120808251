.bg-vabotu-gradient {
	background: #6441a5; /* fallback for old browsers */
	background: -webkit-linear-gradient(to bottom, #2a0845, #6441a5); /* Chrome 10-25, Safari 5.1-6 */
	background-image: linear-gradient(to right, #4c49c8, #4641aa, #40398e, #383172, #302958);
	filter: progid:DXImageTransform.Microsoft.gradient(
			startColorstr='#e73f7c',
			endColorstr='#d81b60',
			GradientType=0
		) !important;
	color: #fff;
}

.error {
	color: red;
}

.wrapper {
	font-size: 13px;
}
/*
 * Page: Login & Register
 * ----------------------
 */
.login-logo {
	font-size: 35px;
	text-align: center;
	margin-bottom: 25px;
	font-weight: 300;
}
.login-logo a {
	color: #444;
}
.login-page {
	position: relative;
	top: 0;
	height: 100vh;
	font-family: 'Avenir Next Medium';
	font-size: 16px;
}
.login-box {
	width: 360px;
	margin: auto;
}
@media (max-width: 768px) {
	.login-box {
		width: 90%;
		margin-top: 20px;
	}
}
.login-box-body {
	background: #fff;
	padding: 20px;
	border-top: 0;
	color: #666;
}
.login-box-body .form-control-feedback {
	color: #777;
}
.login-box-msg {
	margin: 0;
	text-align: center;
	padding: 0 20px 20px 20px;
}
.social-auth-links {
	margin: 10px 0;
}
