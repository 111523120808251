@import './helper/flex.scss';

.table-striped tbody tr:nth-of-type(odd) {
	background-color: #fff;
}

.custom-dropdown-button {
	.dropdown-toggle,
	.btn.btn-secondary.btn-sm {
		border: 1px solid #bfcfe0;
		text-transform: uppercase;
		font-size: 10.4px;
		padding: 5px 15px;
	}
}

.custom-dropdown button {
	text-transform: uppercase;
	font-size: 10.4px;
}

.search-box {
	border: 1px solid #bfcfe0;
	font-size: 12px;
	color: #92aac4;
	background-color: #f8f9fb;
	font-family: 'Avenir Next', fontAwesome !important;
}

// .toggler button {
// 	&:hover {
// 		background-color: #dae0e5;
// 	}
// }

// .toggler button.btn.btn-none.active {
// 	background-color: #dae0e5;
// }

.w-250 {
	width: 250px;
}

.selectbox {
	padding: 2px;
}

.btn-delete {
	background-color: #dc004a;
	border-radius: 50px;
}

.th {
	cursor: pointer;
	i {
		padding-left: 5px;
		color: #7e98b5;
	}
}

table {
	font-size: 12px;
}

.react-bs-table-csv-btn {
	border: 0;
	font-size: 12px;
}

table tbody,
td {
	font-family: 'Avenir Next Regular';
}
.table-link {
	color: #1f3bfc !important;
	&:hover {
		font-style: bold;
		cursor: pointer;
	}
}

.word-break {
	word-break: break-all;
}

button span {
	font-family: 'Avenir Next Demi Bold';
}

.table th,
.table td {
	border-top: 0;
	font-family: 'Avenir Next Medium';
}

.clear-button {
	margin-left: -35px !important;
	z-index: 100;
	border: none;
	background-color: inherit;
	text-indent: 0%;
}

.clear-button-black {
	margin-left: -50px !important;
	z-index: 100;
	border: none;
	// background-color: inherit;
	text-indent: 0%;
	color: black;

	&:hover,
	&:active {
		color: black;
	}
}

.custom-menu-pad {
	padding: 0.25rem 1.5rem;
	color: black;
}

.module-header {
	display: flex;
	align-items: center;
}

.custom-react-table-theme-class {
	.rt-tbody {
		.rt-td {
			font-family: 'Avenir Next Regular';
			color: #757575;
		}
	}
}

.Table__itemCount {
	font-size: 14px;
}

.Table__pagination {
	display: flex;
	justify-content: flex-end;
	padding: 20px 10px 0px;
}

.Table__pageButton {
	font-size: 18px;
	outline: none;
	border: none;
	background-color: transparent;
	cursor: pointer;
	color: #757575 !important;
	margin: 0 5px;
}

.Table__pageButton:disabled {
	cursor: not-allowed;
	color: gray;
}

.Table__pageButton--active {
	font-weight: bold;
	background: black;
	color: white !important;
	width: 30px;
	height: 30px;
	border-radius: 6px;
}

.tabl-search {
	&:focus {
		outline: 0;
	}
}

.back-icon {
	position: absolute;
	right: 15px;
	bottom: 20px;
	color: black;
}

.-sort-desc {
	box-shadow: none !important;
	&:before {
		content: "▼";
		float: right;
		margin-right: 14px;
		color: black;
	}
}

.-sort-asc {
	box-shadow: none !important;
	&:before {
		content: "▲";
		float: right;
		margin-right: 14px;
		color: black;
	}
}

.list-flex ul {
	@extend .flex-1;
}
