@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css");

@font-face {
	font-family: 'Avenir Next Bold';
	src: url("../fonts/AvenirNext-Bold.eot");
	src: url("../fonts/AvenirNext-Bold.eot?#iefix") format('embedded-opentype'),
		url("../fonts/AvenirNext-Bold.woff") format('woff'), url("../fonts/AvenirNext-Bold.ttf") format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next Medium';
	src: url("../fonts/AvenirNext-Medium.eot");
	src: url("../fonts/AvenirNext-Medium.eot?#iefix") format('embedded-opentype'),
		url("../fonts/AvenirNext-Medium.woff") format('woff'), url("../fonts/AvenirNext-Medium.ttf") format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next Regular';
	src: url("../fonts/AvenirNext-Regular.eot");
	src: url("../fonts/AvenirNext-Regular.eot?#iefix") format('embedded-opentype'),
		url("../fonts/AvenirNext-Regular.woff") format('woff'),
		url("../fonts/AvenirNext-Regular.ttf") format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next Demi Bold';
	src: url("../fonts/AvenirNext-DemiBold.eot");
	src: url("../fonts/AvenirNext-DemiBold.eot?#iefix") format('embedded-opentype'),
		url("../fonts/AvenirNext-DemiBold.woff") format('woff'),
		url("../fonts/AvenirNext-DemiBold.ttf") format('truetype');
	font-weight: 200;
	font-style: bold;
}

.demi-bold {
	font-family: 'Avenir Next Demi Bold';
	color: black;
	font-size: 14px;
}

.fontAwsome {
	font-family: FontAwesome;
}
.bold {
	font-family: 'Avenir Next Bold';
	color: black;
	font-size: 20px;
}
