.logo {
	position: relative;
	padding: 7px 0.8rem;
	z-index: 4;

	a.logo-mini {
		opacity: 1;
		float: left;
		width: 34px;
		text-align: center;
		margin-left: 10px;
		margin-right: 12px;
	}

	a.logo-normal {
		display: block;
		opacity: 1;
		padding: 11px 0 8px;
	}

	p {
		float: left;
		font-size: 12px;
		margin: 10px 10px;
		color: $white-color;
		line-height: 20px;
	}

	.simple-text {
		text-transform: uppercase;
		padding: $padding-base-vertical 0;
		display: block;
		white-space: nowrap;
		font-size: $font-size-base;
		color: $white-color;
		text-decoration: none;
		font-weight: $font-weight-normal;
		line-height: 30px;
		overflow: hidden;
	}
}

.wrapper {
	position: relative;
	top: 0;
	height: 100vh;
	font-family: 'Avenir Next Medium';
	font-size: 16px;
	&.wrapper-full-page {
		min-height: 100vh;
		height: auto;
	}
}

// @media all and (min-width: 992px) {
// 	.sidebar .nav > li.active-pro {
// 		position: absolute;
// 		width: 100%;
// 		bottom: 10px;
// 	}
// }

.sidebar,
.off-canvas-sidebar {
	position: fixed;
	top: 0;
	height: 100%;
	bottom: 0;
	width: 260px;
	left: 0;
	z-index: 1030;
	border-right: 1px solid #ddd;
	transition: transform 0.3s ease-out;

	.sidebar-wrapper {
		position: relative;
		height: calc(100vh - 75px);
		overflow: auto;
		width: 260px;
		z-index: 4;
		padding-bottom: 100px;

		.dropdown .dropdown-backdrop {
			display: none !important;
		}

		.navbar-form {
			border: none;
		}
	}
	.sidebar-background {
		position: absolute;
		z-index: 1;
		height: 100%;
		width: 100%;
		display: block;
		top: 0;
		left: 0;
		background-size: cover;
		background-position: center center;

		&:after {
			position: absolute;
			z-index: 3;
			width: 100%;
			height: 100%;
			content: "";
			display: block;
			background: #ffffff;
			opacity: 1;
		}
	}
	&:before,
	&:after {
		display: block;
		content: "";
		opacity: 1;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	&:after {
		@include icon-gradient(#66615B);
		z-index: 3;
	}
	&[data-color="white"] {
		@include sidebar-color($white-color);
		@include sidebar-text-color(#66615B);
	}
	&[data-color="vabotu"] {
		@include sidebar-color($vabotu-bg);
	}
}

.sidebar,
.off-canvas-sidebar {
	position: fixed;
	top: 0;
	height: 100%;
	bottom: 0;
	width: 260px;
	left: 0;
	z-index: 1030;
	border-right: 1px solid #ddd;
	transition: transform 0.3s ease-out;
}
.sidebar .sidebar-wrapper,
.off-canvas-sidebar .sidebar-wrapper {
	position: relative;
	height: calc(100vh - 75px);
	overflow: auto;
	width: 260px;
	z-index: 4;
	padding-bottom: 100px;
}
.sidebar .sidebar-wrapper .dropdown .dropdown-backdrop,
.off-canvas-sidebar .sidebar-wrapper .dropdown .dropdown-backdrop {
	display: none !important;
}
.sidebar .sidebar-wrapper .navbar-form,
.off-canvas-sidebar .sidebar-wrapper .navbar-form {
	border: none;
}
.sidebar .navbar-minimize,
.off-canvas-sidebar .navbar-minimize {
	position: absolute;
	right: 20px;
	top: 2px;
	opacity: 1;
}
.sidebar .logo-tim,
.off-canvas-sidebar .logo-tim {
	border-radius: 50%;
	border: 1px solid #333;
	display: block;
	height: 61px;
	width: 61px;
	float: left;
	overflow: hidden;
}
.sidebar .logo-tim img,
.off-canvas-sidebar .logo-tim img {
	width: 60px;
	height: 60px;
}
.sidebar .nav,
.off-canvas-sidebar .nav {
	margin-top: 20px;
	display: block;
}
.sidebar .nav .caret,
.off-canvas-sidebar .nav .caret {
	top: 14px;
	position: absolute;
	right: 10px;
}
.sidebar .nav li > a + div .nav li > a,
.off-canvas-sidebar .nav li > a + div .nav li > a {
	margin-top: 7px;
}
.sidebar .nav li > a,
.off-canvas-sidebar .nav li > a {
	margin: 10px 15px 0;
	color: #9891b0;
	display: block;
	text-decoration: none;
	position: relative;
	cursor: pointer;
	padding: 10px 8px;
	line-height: 30px;
	opacity: .7;
}
.sidebar .nav li .nav > li > a,
.off-canvas-sidebar .nav li .nav > li > a {
	padding: 5px 8px;
}
.sidebar .nav li.active > a,
.sidebar .nav li.active > a {
	opacity: 1;
	color: #fff;
	font-family: 'Avenir Next Bold';
}
.off-canvas-sidebar .nav li.active > a,
.off-canvas-sidebar .nav li.active > a > i {
	opacity: 1;
	color: #fff;
	font-family: 'Avenir Next Bold';
}
.sidebar .nav li:hover:not(.active) > a,
.off-canvas-sidebar .nav li:hover:not(.active) > a {
	opacity: 1;
	color: #fff;
	.off-canvas-sidebar .nav li:focus:not(.active) > a,
	.sidebar .nav li:focus:not(.active) > a {
		font-family: 'Avenir Next Bold';
	}
}
.sidebar .nav i,
.off-canvas-sidebar .nav i {
	float: left;
	margin-right: 12px;
	line-height: 30px;
	width: 30px;
	text-align: center;
	position: relative;
}
.sidebar .nav p,
.off-canvas-sidebar .nav p {
	margin-bottom: 0;
}
.sidebar .nav .collapse .nav,
.sidebar .nav .collapsing .nav,
.off-canvas-sidebar .nav .collapse .nav,
.off-canvas-sidebar .nav .collapsing .nav {
	margin-top: 0;
}
.sidebar .sidebar-background,
.off-canvas-sidebar .sidebar-background {
	position: absolute;
	z-index: 1;
	height: 100%;
	width: 100%;
	display: block;
	top: 0;
	left: 0;
	background-size: cover;
	background-position: center center;
}
.sidebar .sidebar-background:after,
.off-canvas-sidebar .sidebar-background:after {
	position: absolute;
	z-index: 3;
	width: 100%;
	height: 100%;
	content: "";
	display: block;
	background: #ffffff;
	opacity: 1;
}
.sidebar .logo,
.off-canvas-sidebar .logo {
	position: relative;
	padding: 7px 0.8rem;
	z-index: 4;
}
.sidebar .logo a.logo-mini,
.off-canvas-sidebar .logo a.logo-mini {
	opacity: 1;
	float: left;
	width: 34px;
	text-align: center;
	margin-left: 10px;
	margin-right: 12px;
}
.sidebar .logo a.logo-normal,
.off-canvas-sidebar .logo a.logo-normal {
	display: block;
	opacity: 1;
	padding: 11px 0 8px;
	-webkit-transform: translate3d(0px, 0, 0);
	-moz-transform: translate3d(0px, 0, 0);
	-o-transform: translate3d(0px, 0, 0);
	-ms-transform: translate3d(0px, 0, 0);
	transform: translate3d(0px, 0, 0);
}
.sidebar .off-canvas-sidebar {
	content: '>';
	position: absolute;
	bottom: 0;
	right: 15px;
	height: 1px;
	width: calc(100% - 30px);
	background-color: rgba(255, 255, 255, 0.5);
}
.sidebar .logo p,
.off-canvas-sidebar .logo p {
	float: left;
	font-size: 20px;
	margin: 10px 10px;
	color: #ffffff;
	line-height: 20px;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.sidebar .logo .simple-text,
.off-canvas-sidebar .logo .simple-text {
	text-transform: uppercase;
	padding: 0.5rem 0;
	display: block;
	white-space: nowrap;
	font-size: 1rem;
	color: #ffffff;
	text-decoration: none;
	font-weight: 400;
	line-height: 30px;
	overflow: hidden;
}
.sidebar .logo-tim,
.off-canvas-sidebar .logo-tim {
	border-radius: 50%;
	border: 1px solid #333;
	display: block;
	height: 61px;
	width: 61px;
	float: left;
	overflow: hidden;
}
.sidebar .logo-tim img,
.off-canvas-sidebar .logo-tim img {
	width: 60px;
	height: 60px;
}
.sidebar:before,
.sidebar:after,
.off-canvas-sidebar:before,
.off-canvas-sidebar:after {
	display: block;
	content: ">";
	opacity: 1;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
.sidebar:after,
.off-canvas-sidebar:after {
	background: #66615b;
	background: -webkit-linear-gradient(#66615b 0%, #000 80%);
	background: -o-linear-gradient(#66615b 0%, #000 80%);
	background: -moz-linear-gradient(#66615b 0%, #000 80%);
	background: linear-gradient(#66615b 0%, #000 80%);
	z-index: 3;
}

.navbar-wrapper {
	display: inline-flex;
	align-items: center;

	.navbar-minimize {
		padding-right: 10px;

		.btn {
			margin: 0;
		}
	}

	.navbar-toggle {
		.navbar-toggler {
			padding-left: 0;
		}

		&:hover {
			& .navbar-toggler-bar.bar2 {
				width: 22px;
			}
		}
	}
}

.navbar .navbar-wrapper {
	display: inline-flex;
	align-items: center;
}
.navbar .navbar-wrapper .navbar-minimize {
	padding-right: 10px;
}
.navbar .navbar-wrapper .navbar-minimize .btn {
	margin: 0;
}
.navbar .navbar-wrapper .navbar-toggle .navbar-toggler {
	padding-left: 0;
}
.navbar .navbar-wrapper .navbar-toggle:hover .navbar-toggler-bar.bar2 {
	width: 22px;
}
.user-panel {
	position: absolute;
	bottom: 0;
	color: white;
	z-index: 4;
	font-family: 'Avenir Next Demi Bold';
	width: 260px;
	align-items: center;
	font-size: 15px;

	.dropdown-toggle::after {
		content: none;
	}

	.dropdown {
		padding: 0;
	}

	.dropdown-menu {
		left: 15px !important;
		font-family: 'Avenir Next Regular';
		font-size: 16px;
	}

	.dropdown-item {
		color: #2e2748;
	}
	.dropdown-item:hover {
		background-color: #2e2748;
	}
}

.user-img-sm {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	padding: 5px;
	float: left;
	cursor: 'pointer';
}

.navbar-toggler {
	z-index: 4;
	background-color: white;
	height: 30px;
	width: 30px;
	float: left;
	padding: 5px 5px;
	margin-top: 24px;
	margin-right: 19px;
	margin-left: 7px;
}

.navbar-expand-lg .navbar-toggler {
	display: unset;
}

.sidebar.closed {
	transform: translateX(-70%);

	.main-panel {
		width: $sidebar-mini-width !important;
	}
	// 	.sidebar-wrapper .nav li > a.nav-link > p:after,
	// .user-panel p:after,
	// .logo a:after {
	// 	display:none;
	// }
}

.sidebar.open {
	transform: translateX(0);
	// .sidebar-wrapper .nav li a.nav-link p:after,
	// .user-panel p:after,
	// .logo a:after {
	// 	display:unset;
	// }
}

.navbar {
	padding: 0;
}

.hide {
	opacity: 0 !important;
}

.icn-right {
	float: right !important;
}

.fa-home {
	font-size: 22px;
}

.fa-envelope {
	font-size: 17px;
}
